.public-property-container {
  width: 100%;
  height: 100%;
}

.public-property-container header {
  width: 100%;
  padding: 20px 10px;
}

.public-property-container header h1 {
  font-size: 24px;
  font-weight: bold;
  color: rgb(243, 206, 143);
}

.public-property-main-content {
  height: 100%;
}

.public-property-image-container {
  height: 100%;
}

.public-property-image-container .desktop-tablet-pp-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.public-property-image-container .mobile-pp-img {
  display: none;
}

.public-property-content-container {
  height: 100%;
  padding: 0px !important;
  overflow-y: auto;
}

.public-property-details-container {
  border-top: 1px solid rgb(243, 206, 143);
  padding: 20px;
}

.public-property-details-container h1 {
  font-size: 22px;
  font-weight: bold;
}

.public-property-details-container p {
  font-size: 16px;
  font-weight: 400;
}

.public-property-content-divider {
  width: 100%;
  color: rgb(243, 206, 143);
  border-top: 1px solid !important;
  opacity: 1 !important;
  margin-top: 10px;
  margin-bottom: 0px;
}

.products-list {
  padding: 10px 20px !important;
}

.products-list h1 {
  padding: 0px;
  margin-bottom: 10px;
}

.pp-list-search-input {
  width: 100;
  padding: 5px;
  border: none;
  border-bottom: 1px solid black;
  background: transparent;
  margin-bottom: 30px;
}

.pp-list-search-input:focus,
.pp-list-search-input:hover {
  border-bottom: 1px solid red;
}

.pp-store-load-button {
  background: transparent;
  border: 1px solid rgb(243, 206, 143);
  padding: 10px 25px;
  margin: 0px 10px;
  color: rgb(243, 206, 143);
}

.pp-store-load-button:hover {
  background-color: rgb(243, 206, 143);
  color: white;
}

.product-list-product:hover {
  cursor: pointer;
}

.selected-product-container {
  width: 100%;
  height: 100%;
}

.selected-product-image-container {
  height: 100%;
}

.selected-product-image-container:hover {
  cursor: pointer;
}

.selected-product-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.selected-product-content-container {
  height: 100%;
  background: white;
}

.selected-product-details-container {
  padding: 20px;
}

.selected-product-checkout-container {
  padding: 20px;
}

.selected-product-checkout-container label {
  font-weight: 500;
}

.selected-product-checkout-container .amount-input {
  width: 100%;
  margin-bottom: 20px;
  background: transparent;
  border: none;
  border-bottom: 1px solid black;
  color: rgb(243, 206, 143);
}

.selected-product-checkout-button {
  padding: 10px 40px;
  background: rgb(243, 206, 143);
  border: none;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.selected-product-checkout-button:hover {
  background: transparent;
  border: 1px solid rgb(243, 206, 143);
  color: rgb(243, 206, 143);
}

.selected-product-checkout-container .cancel-button {
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  .public-property-container {
    overflow-y: auto;
  }

  .public-property-content-container {
    overflow-y: unset;
  }

  .public-property-image-container {
    height: 200px;
  }

  .public-property-image-container .desktop-tablet-pp-img {
    display: none;
  }

  .public-property-image-container .mobile-pp-img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .public-property-details-container {
    border-top: none;
  }

  .selected-product-image-container {
    height: 200px;
  }
}
