.pl-recommendations-button {
  width: 100%;
  padding: 20px 0px;
  background: white;
  color: rgb(243, 206, 143);
  border: 1px solid white;
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
}

.pl-recommendations-button:hover {
  background: rgb(243, 206, 143);
  color: white;
}
