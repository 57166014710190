.desktop-only-container {
  display: none;
}

.home-container {
  width: 100%;
  height: 100%;
  background-color: rgb(243, 206, 143);
  overflow: hidden;
  color: white;
  padding: 0px 100px;
  overflow-y: auto;
}

.home-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.home-brand-label-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
}

.home-brand-label-container h1 {
  font-weight: bold;
  color: white;
}

.header-line {
  width: 100%;
  color: white;
  border-top: 2px solid !important;
  opacity: 1 !important;
  margin: 0px;
}

.home-header {
  margin-bottom: 10px !important;
}

.home-left-col-actions {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0px !important;
}

.home-left-col-actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  color: rgb(243, 206, 143);
  padding: 1px 6px 1px 0px !important;
}

.home-left-col-actions button i {
  font-size: 44px;
  color: white;
}

.home-right-col-actions {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  padding: 0px !important;
}

.home-right-col-actions button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  color: rgb(243, 206, 143);
}

.home-right-col-actions button i {
  font-size: 44px;
  color: white;
}

.home-action-items-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.home-action-items-container button {
  background: white;
  border: 1px solid white;
  color: rgb(243, 206, 143);
  padding: 10px 20px;
}

.home-action-items-container button i {
  font-size: 30px;
}

.home-content-type-container {
  height: 90%;
  color: white;
}

#home-form-input {
  border-bottom-color: white !important;
  color: white !important;
}

#home-action-button {
  background: white;
  color: rgb(243, 206, 143);
}

#home-action-button:hover {
  background: transparent;
  color: white;
}

.home-list-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
}

.home-list-col-padding-box {
  box-sizing: border-box;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.home-list-col-wrapper {
  height: 100%;
  width: 100%;
  border-radius: inherit;
}

.home-list-col-img {
  height: 100%;
}

.home-list-col-img:hover {
  cursor: pointer;
}

.home-list-col-details {
  padding: 20px !important;
}

.home-list-content-details {
  color: black;
  word-break: break-all;
}

.home-list-content-details h1 {
  font-size: 26px;
  font-weight: bold;
}

.home-list-content-details:hover {
  cursor: pointer;
}

.home-list-details-line {
  width: 100%;
  color: rgb(243, 206, 143);
  border-top: 2px solid !important;
  opacity: 1 !important;
  margin: 20px 0px;
}

.home-list-actions-container {
  display: inline;
}

.home-list-actions-container button {
  background: rgb(243, 206, 143);
  padding: 5px 10px;
  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
}

.home-list-actions-container button:first-child {
  margin-right: 10px !important;
}

.home-search-bar-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  padding: 0px !important;
  margin-bottom: 20px;
}

.home-search-bar-container select {
  width: 15%;
  margin-right: 5px;
}

.home-search-bar-container input {
  width: 75%;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid white;
  background: white;
}

@media screen and (max-width: 1300px) {
  .home-header-container {
    height: 100px;
  }
  .home-content-type-container {
    max-height: 100%;
  }
}

@media screen and (max-width: 960px) {
  .home-left-col-actions button i {
    font-size: 25px;
    color: white;
  }
  .home-search-bar-container {
    display: flex;
    flex-flow: column nowrap;
  }

  .home-search-bar-container select {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .home-search-bar-container input {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .home-container {
    padding: 0px 20px;
  }

  .home-right-col-actions button i {
    font-size: 30px;
  }

  .home-action-items-container button {
    padding: 5x 10px;
  }

  .home-action-items-container button i {
    font-size: 20px;
  }
}

@media screen and (max-width: 333px) {
  .home-left-col-actions button i {
    font-size: 16px;
    color: white;
  }
}
