.account-settings-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.account-settings-container h1 {
  margin-bottom: 10px;
}

.account-settings-container h2 {
  margin-bottom: 10px;
}

.account-settings-container .common-home-section-header-label {
  color: white !important;
}

.account-settings-container p {
  padding-bottom: 10px;
}

.payments-link {
  color: blue !important;
  margin-bottom: 10px !important;
}

.payments-link a {
  color: blue !important;
  text-decoration: none;
}

.payments-link:hover {
  cursor: pointer;
}

.account-settings-container button {
  background: white;
  color: rgb(243, 206, 143);
  border-radius: 10px;
}

.account-settings-container button {
  margin-bottom: 10px !important;
}

.settings-input {
  color: white !important;
}

.settings-input:hover,
.settings-input:focus {
  border-bottom: 1px solid red !important;
}
