.properties-list-container h2 {
  padding: 0px;
  margin-bottom: 20px;
}

.create-new-property-form {
  padding-top: 10px;
}

#qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  z-index: 2;
  margin-bottom: 20px;
}

#qr-code img {
  height: 100%;
  width: auto;
}

.qr-container-button {
  width: 50% !important;
  z-index: 2;
  background: rgb(243, 206, 143);
  padding: 5px 10px;
  color: white;
  border: 1px solid transparent;
  border-radius: 10px;
}

.view-public-store-link {
  color: white;
  margin-bottom: 20px;
}
